import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {getImage} from "gatsby-plugin-image";
import {convertToBgImage} from "gbimage-bridge";
import Header from "../components/header";
import BackgroundImage from "gatsby-background-image";
import Footer from "../components/footer";

export default function ContactPage() {
    const {grayBg} = useStaticQuery(
        graphql`
        query {
            grayBg: file(relativePath: { eq: "gray-white.png" }) {
                childImageSharp {
                    gatsbyImageData(
                      breakpoints: [2500]
                      quality: 100
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
        }
    `
    );

    const bgGrayImage = convertToBgImage(getImage(grayBg));

    return (
        <main className="w-full">
            <BackgroundImage Tag="section" className="relative min-h-screen lg:min-h-3-4-screen w-full" {...bgGrayImage}>
                <div className="absolute w-full">
                    <Header mode="dark"/>
                </div>
                <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 px-8 lg:px-10 pt-28 lg:pt-52 font-poppins">
                    <div className="lg:p-5 lg:p-10">
                        <div className="text-3xl lg:text-4xl mb-10">¡Comencemos ahora!</div>
                        <div>¡Escríbenos cómo podemos ayudarte y formemos un equipo increíble!</div>
                    </div>
                    <div className="mt-10 lg:mt-0 lg:p-10 font-poppins">
                        <form method="post" action="https://getform.io/f/b340ddb9-37b9-47d5-9806-de99071540af">
                            <div><label htmlFor="name">Contacto</label></div>
                            <div className="grid grid-cols-2 gap-5">
                                <input type="text" name="name" placeholder="Nombre" required/>
                                <input type="text" name="lastname" placeholder="Apellidos" required/>
                            </div>

                            <div className="mt-10"><label htmlFor="email">Email</label></div>
                            <input className="w-full" type="email" name="email" placeholder="usuario@ejemplo.com"
                                   required/>

                            <div className="mt-10"><label htmlFor="message">¿Cómo te ayudamos?</label></div>
                            <textarea className="w-full" name="message" rows={5} required/>

                            <div className="mt-10"><label htmlFor="phone">Teléfono</label></div>
                            <input className="w-full" type="tel" name="phone"/>

                            <div className="mt-10">
                                <button type="submit" className="rounded-full border-2 py-3 px-5">Enviar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </BackgroundImage>
            <Footer/>
        </main>
    );
}